import React from 'react'
import AppBar from '@mui/material/AppBar'
import { Link, Outlet, useLocation } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import MpSvgIcon from '../shared/atom/MpSvgIcon'
import Box from '@mui/material/Box'
import { useAppDispatch } from '../../redux/storeExports'
import {
  signInWithApple,
  signInWithGoogle,
} from '@meprism/shared/src/redux/authentication/authenticationSlice'
import Typography from '@mui/material/Typography'
import { Button, Container, useMediaQuery } from '@mui/material'
import MpTheme from '@meprism/shared/src/config/MpTheme'
import { ThemeProvider } from '@emotion/react'
import { MpReTheme, theme } from '../../theme/OnboardingTheme'

type Variant = 'sign in' | 'create'

export const AuthFooter = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const variant: Variant = location.pathname.includes('create')
    ? 'create'
    : 'sign in'

  return (
    <Stack
      direction={'column'}
      spacing={2}
      sx={{
        width: '100%',
        maxWidth: MpTheme.layouts.widths.sm,
        mt: 2,
        mb: 20,
      }}>
      <Box
        sx={{
          pt: 5,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          pb: 5,
        }}>
        <Typography variant={'caption'}>Sign Up With Socials</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Button
          disableElevation
          variant={'contained'}
          color={'lightPurple'}
          onClick={() =>
            dispatch(signInWithGoogle(location.state?.from || '/'))
          }
          sx={{
            width: 'auto',
            height: 'auto',
            padding: '8px',
            fontSize: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center contents horizontally
          }}>
          <MpSvgIcon icon={'google'} height={20} width={20} />
          {/* {variant === 'create' ? 'Sign Up With Google' : 'Log In With Google'} */}
        </Button>
        <Button
          disableElevation
          variant={'contained'}
          color={'lightPurple'}
          onClick={() => dispatch(signInWithApple(location.state?.from || '/'))}
          sx={{
            width: 'auto',
            height: 'auto',
            padding: '8px',
            fontSize: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center contents horizontally
          }}>
          <MpSvgIcon icon={'apple'} height={20} width={20} />
          {/* {variant === 'create' ? 'Sign Up With Apple' : 'Log In With Apple'} */}
        </Button>
      </Box>

      <Box
        sx={{
          pt: 5,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}>
        {variant === 'create' ? (
          <Typography variant={'body4'}>
            Already have an account?{' '}
            <Link
              to={'signin'}
              style={{
                textDecoration: 'underline',
                color: MpReTheme.colors.secondary[600],
              }}>
              Sign In
            </Link>{' '}
          </Typography>
        ) : (
          <Typography variant={'body4'}>
            {`Don't have an account? `}
            <Link
              to={'/login/create'}
              style={{
                textDecoration: 'underline',
                color: MpReTheme.colors.secondary[600],
              }}>
              Sign Up
            </Link>{' '}
          </Typography>
        )}
      </Box>
    </Stack>
  )
}

export const SignInLayout = () => {
  const location = useLocation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const variant: Variant = location.pathname.includes('create')
    ? 'create'
    : 'sign in'
  //@ts-ignore
  Intercom('update')
  const SignInScreen = location.pathname.includes('signin')
  return (
    <>
      <ThemeProvider theme={theme}>
        <AppBar
          position={'relative'}
          color={'transparent'}
          elevation={0}
          sx={{
            maxWidth: MpTheme.layouts.widths.sm,
            mx: 'auto',
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'center',
            flexDirection: 'column',
            my: 10,
          }}>
          <Box sx={{ mt: SignInScreen ? 20 : 'unset' }}>
            <Typography
              variant={'h2'}
              sx={{ mx: isMobile ? (SignInScreen ? 3 : 6) : 3 }}>
              {variant === 'create' ? 'Let’s get started' : 'Log In'}
            </Typography>
          </Box>
        </AppBar>
        <Container
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            mb: 8,
            maxWidth: 'md',
          }}
          maxWidth={'md'}>
          <Outlet />
          {variant === 'create' && <AuthFooter />}
        </Container>
      </ThemeProvider>
    </>
  )
}
