import { Container, Grid, useMediaQuery, Box } from '@mui/material'
import React from 'react'
import scan from '@meprism/shared/src/assets/icons/scan.svg'
import user from '@meprism/shared/src/assets/icons/participating.svg'
import { theme } from '../../theme/DashboardTheme'
import ScannedSites from '../shared/atom/ScannedSites'
import { useAppSelector } from '../../redux/storeExports'
import { PeopleFinderSelectors } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import PieChartCard from '../shared/atom/PieChartCard'
import ExposureStatsCard from '../shared/atom/ExposureStats'
import DashboardGrid from '../shared/molecule/DashboardGrid'

import MainHeader from '../shared/organism/MainHeader'
import { selectActiveGrant } from '../../redux/product/ProductSlice'

const DashboardView = () => {
  const userStats = useAppSelector(
    PeopleFinderSelectors.selectBrokerStats,
  )

  const grant = useAppSelector(selectActiveGrant)
  const removing = userStats?.brokers_in_progress ?? 0
  const removed = userStats?.brokers_removed ?? 0
  const color2 = grant ? '#27AE60' : '#88878B'
  const color1 = grant ? '#E2B93B' : '#A1A0A4'
  const profilefound = userStats?.brokers_found ?? 0
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container
      fixed
      maxWidth={'lg'}
      sx={{ mb: 16, mt: '12px', margin: '1rem' }}>
      <MainHeader title={'Dashboard'} />
      {!isMobile && (
        <Box mb={5}>
          <ScannedSites count={''} />
        </Box>
      )}

      {grant ? (
        <Grid container columnSpacing={5} rowSpacing={5}>
          <Grid item xs={12} md={6}>
            <PieChartCard
              title="Removal Status"
              removed={removed}
              removing={removing}
              color1={color1}
              color2={color2}
              icon1={user}
              bgcolor={''}
              title1={'Removing'}
              title2={'Removed'}
              icon2={scan}
              totalcount1={removing}
              totalcount2={removed}
              grant={grant}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ExposureStatsCard
              title="Exposure Stats"
              removed={125}
              removing={137}
              color1={'#426BF0'}
              color2={'#C67EFF'}
              icon1={user}
              bgcolor={'#C67EFF33'}
              title1={'Profile Found'}
              title2={'Brokers Scanned'}
              icon2={scan}
              totalcount1={profilefound}
              totalcount2={91}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container columnSpacing={5} rowSpacing={5}>
          <Grid item xs={12} md={6}>
            <ExposureStatsCard
              title="Exposure Stats"
              removed={125}
              removing={137}
              color1={'#426BF0'}
              color2={'#C67EFF'}
              icon1={user}
              bgcolor={'#C67EFF33'}
              title1={'Profile Found'}
              title2={'Brokers Scanned'}
              icon2={scan}
              totalcount1={profilefound}
              totalcount2={91}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PieChartCard
              title="Removal Status"
              removed={1}
              removing={1}
              color1={color1}
              color2={color2}
              icon1={user}
              bgcolor={''}
              title1={'Removing'}
              title2={'Removed'}
              icon2={scan}
              totalcount1={0}
              totalcount2={0}
              grant={grant}
            />
          </Grid>
        </Grid>
      )}
      <Box mt={5}>
        <DashboardGrid />
      </Box>
    </Container>
  )
}

export default DashboardView
