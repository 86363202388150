import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { useSelector } from 'react-redux'
import { AnalyticsService } from '../../services/AnalyticsService'
import { Logger } from '@meprism/app-utils'
import { PROD_MEPRISM_APP_INFO } from '../../utils/constants'
import { zip } from '../../utils/helpers'
import Cookies from 'universal-cookie'
import { getMuidFromToken } from '@meprism/shared/src/services/AuthenticationService'
import { startAppListening } from '../listenerMiddleware'
import { postConfirmLogin } from '@meprism/shared/src/redux/authentication/authenticationSlice'
export * from '@meprism/shared/src/redux/authentication/authenticationSlice'

const UTM_PARAMETERS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
]

// this builds a deep link into the production app that passes on its UTMs to the app
// this is to be used in case of something like a landing page that doesn't ever
// identify a user
export const buildLinkWithAttribution = createAsyncThunk(
  'buildLinkWithAttribution',
  async (path: string): Promise<string> => {
    const promises: Promise<string>[] = UTM_PARAMETERS.map((param) =>
      AnalyticsService.getProperty(param),
    )
    const allUtms = await Promise.all(promises)
    // only capture the ones that are set
    const utms = Object.fromEntries(
      zip(UTM_PARAMETERS, allUtms).filter(([_, value]) => value),
    )
    const innerLink = new URL(path, PROD_MEPRISM_APP_INFO.baseAppLinkUrl)
    const outerLink = new URL(PROD_MEPRISM_APP_INFO.baseDynamicLinkUrl)

    // set them on the inner link
    Object.entries(utms).forEach(([key, value]) => {
      innerLink.searchParams.set(key, value as string)
      outerLink.searchParams.set(key, value as string)
    })

    // we then have to set the link param of the outer link as the encoded inner link
    outerLink.searchParams.set('link', innerLink.toString())
    // and set a bunch more stuff
    Object.entries(PROD_MEPRISM_APP_INFO.params).forEach(([key, value]) => {
      outerLink.searchParams.set(key, value)
    })

    Logger.info(`Built custom dynamic link: ${outerLink.toString()}`)

    return outerLink.toString()
  },
)

startAppListening({
  actionCreator: postConfirmLogin.fulfilled,
  effect: async (_, { extra, getState }) => {
    const cookies = new Cookies()
    try {
      const gaid = cookies.get('_ga')
      if (gaid) {
        extra.AnalyticsManager.union('gaid', gaid)
      }
    } catch (error) {
      // nothing to do here, don't want to interfere
      // could error if ex user blocked access to cookie
    }
    const state = getState()
    let hasConverted = false
    try {
      const muid = await getMuidFromToken()
      //@ts-ignore
      window.intercomSettings = {
        app_id: 'p9mftca9',
        custom_launcher_selector: '#intercom-launcher',
        user_id: muid,
        email: state.authentication.accountEmail,
      }
      //@ts-ignore
      Intercom('update')
      hasConverted = await extra.API.get('KVS', `/${muid}/hasConverted`, {})
    } catch (error) {
      Logger.error(error)
    }
    const clickid = cookies.get('irclickid')
    if (!hasConverted && clickid) {
      extra.API.post('User', '/affiliate/conversion', {
        body: { clickid: clickid },
      })
    }
  },
})

export const AuthenticationSelector = (): RootState['authentication'] =>
  useSelector((state: RootState) => state.authentication)
export const IsUserAuthenticatedSelector = (): boolean =>
  useSelector((state: RootState) => !!state.authentication?.muid)
