import React from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../../redux/storeExports';
import { PeopleFinderSelectors } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice';
import scanningAnimation from '@meprism/shared/src/assets/lottie/scan.gif';
import shield from '@meprism/shared/src/assets/icons/business_userscan.svg';

const SCAN_ACTIVE_THRESHOLD_HOURS = 3;
export const ScanWidget = () => {
  const profile = useAppSelector(PeopleFinderSelectors.selectPeopleFinderProfile);
  const lastScanStart = profile?.mp_removal_setupinfo?.last_scan_start;

  if (!lastScanStart) {
    return null;
  }

  const lastScanStartDate = new Date(lastScanStart);
  const currentTime = new Date();
  const hoursDifference = (currentTime.getTime() - lastScanStartDate.getTime()) / (1000 * 60 * 60);
  
  if (hoursDifference < SCAN_ACTIVE_THRESHOLD_HOURS) {
    return (
      <Box sx={{ alignItems: 'flex-end', flexDirection: 'column', display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <img src={scanningAnimation} width='24px' alt="scanning" />
          <Typography
            variant={'body2'}
            sx={{
              color: 'caption',
              marginLeft: '10px',
              display: { sm: 'block', xs: 'none' }
            }}
          >
            Scanning in progress
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ alignItems: 'flex-end', flexDirection: 'column', display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <img 
          src={shield} 
          alt="shield" 
          style={{ 
            width: '24px', 
            height: '24px', 
            display: 'block', 
            margin: 'auto',
            marginRight: '10px' 
          }} 
        />
        <Typography
          variant={'caption'}
          sx={{
            display: { xs: 'none', sm: 'block' }
          }}
        >
          Scan complete:{' '}
          {lastScanStartDate.toLocaleDateString()}
        </Typography>
      </Box>
    </Box>
  );
};