import React from 'react'
import { defer, Outlet, redirect } from 'react-router-dom'
// import { MpHeader } from '../shared/organism/MpHeader'
import {
  fetchBrokers,
  fetchPeopleFinderProfile,
} from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import message from '@meprism/shared/src/assets/icons/messenger.png'
import { useInboxSocket } from '../../utils/hooks/useInboxSocket'
import { persistor, store } from '../../redux/store'
import { FetchProfile } from '@meprism/shared/src/redux/profile/ProfileSlice'
import { fetchAlerts } from '@meprism/shared/src/redux/alert/AlertSlice'
import {
  checkPendingOffers,
  fetchGrants,
} from '../../redux/product/ProductSlice'
import { Logger } from '@meprism/app-utils'
import { Box, useMediaQuery } from '@mui/material'
import { theme } from '../../theme/DashboardTheme'
// import { MpHeader } from '../shared/organism/MpHeader'

// 3 hours in milliseconds
export const timeElapsed: number = 3 * 60 * 60 * 1000

export const mainLayoutLoader = async () => {
  // Wait for persist rehydration
  persistor.persist()
  if (!persistor.getState().bootstrapped) {
    await new Promise((resolve) => {
      const unsubscribe = persistor.subscribe(() => {
        if (persistor.getState().bootstrapped) {
          unsubscribe();
          resolve(true);
        }
      });
    });
  }
  const state = store.getState();
  const previousFetchTime = state.peopleFinder.previousFetchTime;
  const now = new Date().getTime();
  try {
    store.dispatch(fetchAlerts())
    store.dispatch(fetchGrants())
    store.dispatch(checkPendingOffers())
    const peopleFinderProfile = store
      .dispatch(fetchPeopleFinderProfile())
      .unwrap()
    // Check if 3 hours have passed since last broker fetch
    const brokerList = !previousFetchTime || (now - previousFetchTime >= timeElapsed)
      ? await store.dispatch(fetchBrokers())
      : state.peopleFinder.brokers
    const profile = store.dispatch(FetchProfile()).unwrap()
    return defer({ peopleFinderProfile, brokerList, profile })
  } catch (error) {
    Logger.error(`Error fetching : ${error} ${error?.message}`)
    return redirect('/onboarding')
  }
}


export const MainLayout = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  useInboxSocket({ fqdn: process.env.REACT_APP_API_FQDN as string })
  const stylesbottom = {
    bottomRightIcon: {
      position: 'fixed',
      bottom: 20,
      zIndex: 9999,
      right: 20
    },
  }
  return (
    <>
      {/* <MpHeader /> */}

      <Outlet />
      {!isMobile && <Box sx={{ ...stylesbottom.bottomRightIcon, cursor: 'pointer' }}>
        <img
          src={message}
          id={'intercom-launcher'}
          alt="Description of the image"
          style={{
            maxWidth: '50px',
            maxHeight: '50px',
            marginRight: '10px',
          }}
        /></Box>}
    </>
  )
}
