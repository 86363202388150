import React, { useState, useEffect, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import {
  forgotPassword,
  submitForgotPassword,
} from '../../services/AuthenticationService'
import { Container, Box, Button, Typography } from '../shared/MaterialExports'
import { MpTypography } from '../shared'
import PasswordField from '../shared/atom/PasswordField'
import ValidationHint from '../shared/atom/MpValidationHint'
import { AnalyticsService } from '../../services/AnalyticsService'
import { useAppDispatch } from '../../app/hooks'
import { AuthLoader } from '../gateways/AuthLoader'
import { signInWithEmail } from '@meprism/shared/src/redux/authentication/authenticationSlice'
import useMediaQuery from '@mui/material/useMediaQuery'
import { theme, fonts } from '../../theme/OnboardingTheme'

export interface ChangePasswordRouteParam {
  username: string
  from?: Location
}

const styles = {
  button: {
    background: '#1A1B20',
    border: '2px solid #C67EFF',
    fontFamily: fonts.Inter.SemiBold,
    color: '#C67EFF',
    minWidth: '180px',
    width: '100%',
    '&.Mui-disabled': {
      background: '#426BF0',
      color: '#C67EFF',
    },
    ':hover': {
      background: '#1A1B20',
      color: '#C67EFF',
    },
  },
};

const minPasswordLength = 10

const ChangePassword = () => {
  const { state } = useLocation()
  const from = (state as ChangePasswordRouteParam)?.from || '/'
  const { username } = state as ChangePasswordRouteParam
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // OTP States
  const [otp, setOtp] = useState<string>('')
  const [otpErrorText, setOtpErrorText] = useState<string>('')

  // Password States
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [shouldDisplayPasswordInfo, setShouldDisplayPasswordInfo] = useState<boolean>(false)
  const [shouldShowMatchingPasswords, setShouldShowMatchingPasswords] = useState<boolean>(false)
  const [hasSufficientLength, setHasSufficientLength] = useState<boolean>(false)
  const [hasUpperCase, setHasUpperCase] = useState<boolean>(false)
  const [hasSymbol, setHasSymbol] = useState<boolean>(false)
  const [hasMatchingPassword, setHasMatchingPassword] = useState<boolean>(true)

  const isPasswordValid = hasSufficientLength && hasUpperCase && hasSymbol && hasMatchingPassword
  const isFormValid = isPasswordValid && otp.length > 0

  useEffect(() => {
    setHasSufficientLength(password.length >= minPasswordLength)
    setHasUpperCase(new RegExp(/[A-Z]/).test(password))
    setHasSymbol(new RegExp(/[^A-Za-z0-9]/).test(password))
    setHasMatchingPassword(password === confirmPassword)
  }, [password, confirmPassword])

  const onPasswordReset = async () => {
    try {
      await submitForgotPassword(username, otp, password)
      await dispatch(
        signInWithEmail({ username, password } as {
          username: string
          password: string
        })
      ).unwrap()
      navigate('/changePasswordSuccess', { state: from })
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'CodeMismatchException':
            setOtpErrorText('The code you have entered is incorrect. Please try again')
            break
          case 'ExpiredCodeException':
            await forgotPassword(username)
            setOtpErrorText(`The code you entered has expired. We have sent a new one to ${username}`)
            break
          case 'LimitExceededException':
            setOtpErrorText(
              `Unfortunately, you have exceeded a limit on the number of failed login attempts. Please wait 15 minutes and try again.`
            )
            AnalyticsService.error('CognitoLimit', error)
            break
          default:
            setOtpErrorText('An error occurred processing your request. Please try again later.')
            AnalyticsService.error(`Unhandled Authorization error: `, error)
            break
        }
      } else {
        AnalyticsService.error(`Unhandled Authorization error: `, error)
      }
    }
  }

  const handleResendCode = useCallback(
    async (callback: (message: string) => void) => {
      try {
        await forgotPassword(username);
        callback('A new verification code has been sent.');
      } catch (error) {
        callback('Failed to resend code. Please try again later.');
      }
    },
    [username]
  );



  return (
    <AuthLoader>
      <Container fixed maxWidth={'sm'}>
        <Box
          mt="10px"
          display="flex"
          flexDirection="column"
          alignItems="left"
          justifyContent="left">
          <Typography variant={'h2'} sx={{ marginTop: '50px', marginBottom: '10px', textAlign: 'center' }}>
            Reset Your Password
          </Typography>

          {/* OTP Section */}
          <MpTypography mt="40px" mb="20px" variant="subheader" textAlign="center">
            {`Enter the verification code sent to ${username}`}
          </MpTypography>
          <OtpInput
            value={otp}
            onChange={setOtp}
            inputStyle={{
              width: isMobile ? '16.666%' : '25%',
              height: isMobile ? '42px' : '60px',
              border: '2px solid #EFEDFDB2',
              borderRadius: '10px',
              background: '#1A1B20',
              color: '#ffffff',
            }}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
          {isMobile ? (
            <Box sx={{ minHeight: otpErrorText ? 100 : 30 , mt: 3 }}>
              <MpTypography color={'error'}>{otpErrorText}</MpTypography>
            </Box>
          ) : (
            <Box sx={{ minHeight: 60 , mt: 3  }}>
              <MpTypography color={'error'}>{otpErrorText}</MpTypography>
            </Box>
          )}
          <PasswordField
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onFocus={() => setShouldDisplayPasswordInfo(true)}
            placeholder={'Password'}
            variant={'outlined'}
            sx={{ width: '100%', margin: 1 }}
            autoComplete="new-password"
          />
          <PasswordField
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            onFocus={() => setShouldShowMatchingPasswords(true)}
            placeholder="Confirm Password"
            variant={'outlined'}
            sx={{ width: '100%', margin: 1 }}
            autoComplete="new-password"
          />
          <Box sx={{ minHeight: shouldDisplayPasswordInfo ? 100 : 50 }}>
            {shouldDisplayPasswordInfo && (
              <Box>
                <ValidationHint
                  isValid={hasSufficientLength}
                  text={`At least ${minPasswordLength} characters`}
                />
                <ValidationHint
                  isValid={hasUpperCase}
                  text={'Contains at least one upper case letter'}
                />
                <ValidationHint isValid={hasSymbol} text={'Contains number or symbol'} />
                {shouldShowMatchingPasswords && (
                  <ValidationHint isValid={hasMatchingPassword} text={'Passwords match'} />
                )}
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 1,
              mb: 2,
              flexDirection: ['column', 'row'],
              width: '100%',
            }}>
            <Button
              variant={'contained'}
              disabled={!isFormValid}
              sx={{
                minWidth: '180px',
                width: '100%',
                mb: [2, 0],
                mr: [0, '10px'],
              }}
              onClick={onPasswordReset}>
              Reset Password
            </Button>
            <Button
              onClick={() =>
                handleResendCode((message) => {
                  setOtpErrorText(message)
                })
              }
              sx={styles.button}
            >
              Resend Code
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              mt: 2,
            }}
          >
            <Button
              onClick={() => navigate(-1)}
              sx={styles.button}
            >
              Change Email
            </Button>
          </Box>
        </Box>
      </Container>
    </AuthLoader>
  )
}

export default ChangePassword