import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import { AuthenticationReducer } from '@meprism/shared/src/redux/authentication/authenticationSlice'
import { ExportReducer } from './export/ExportSlice'
import { PeopleFinderReducer } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import { ProfileReducer } from '@meprism/shared/src/redux/profile/ProfileSlice'
import { AlertReducer } from '@meprism/shared/src/redux/alert/AlertSlice'
import { ProductReducer } from './product/ProductSlice'
import { persistBrokers } from './persistBrokers'

export const appReducer = combineReducers({
  authentication: AuthenticationReducer,
  alerts: AlertReducer,
  export: ExportReducer,
  peopleFinder: PeopleFinderReducer,
  profile: ProfileReducer,
  product: ProductReducer
})

type PersistedState = ReturnType<typeof appReducer> & {
  _persist: { version: number; rehydrated: boolean }
};

const persistedReducer = persistReducer<ReturnType<typeof appReducer>>(
  persistBrokers,
  appReducer
)

export const RootReducer = (
  state: PersistedState | undefined,
  action: any
) => {
  if (action.type === 'USER_LOGOUT') {
    return persistedReducer(undefined, action)
  }

  return persistedReducer(state, action)
}
