export const BROKER_EXECUTION_STATUSES = [
  { title: 'new', id: 'new' },
  { title: 'optout_in_progress', id: 'optout_in_progress' },
  { title: 'waiting_for_verification', id: 'waiting_for_verification' },
  { title: 'completed', id: 'completed' },
]

export const BROKER_STATUSES = [
  { title: 'new', id: 'new' },
  { title: 'removed', id: 'removed' },
  { title: 'optout_in_progress', id: 'optout_in_progress' },
  { title: 'waiting_for_verification', id: 'waiting_for_verification' },
]

export const BROKER_LISTS = [
  'beenverified.com',
  'checksecrets.com',
  'clustrmaps.com',
  'infotracer.com',
  'mylife.com',
  'nuwber.com',
  'peoplefinders.com',
  'peoplewizard.net',
  'radaris.com',
  'spokeo.com',
  'rocketreach.com',
]

// constants for people finder events in PeopleFinderSlice.ts
export const PEOPLE_FINDER_BROKER_STATUS_CHANGE = 'PeopleFinderBrokerStatusChange';
export const PEOPLE_FINDER_NEW_BROKER_DETAIL_FOR_MUID = 'PeopleFinderNewBrokerForMuid';
export const PEOPLE_FINDER_NEW_EXPOSURE = 'PeopleFinderNewExposure';
export const PEOPLE_FINDER_OPT_OUT_STATUS_CHANGED = 'PeopleFinderOptOutStatusChanged';
export const PEOPLE_FINDER_SCAN_START = 'PeopleFinderScanStart';
export const PEOPLE_FINDER_SCAN_COMPLETE = 'PeopleFinderScanComplete';
